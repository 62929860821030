import { useEffect, useState } from "react";
const useScroll = () => {
	const [scroll, setScroll] = useState(window.scrollY);
	const handleResize = () => {
		setScroll(window.scrollY);
	};
	useEffect(() => {
		handleResize();
		window.addEventListener("scroll", handleResize);
		return () => {
			window.removeEventListener("scroll", handleResize);
		};
	}, []);
	return scroll;
};

export default useScroll;
