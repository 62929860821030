import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import NextSlide from "../../../components/NextSlide";
import PrevSlide from "../../../components/PrevSlide";
import { cn } from "../../../utils/cn";
import { motion } from "framer-motion";
import { PlatformData } from "../../../utils/data/platform";

const DefiPotentials = () => {
  const [active, setActive] = React.useState(PlatformData[0]);

  return (
    <section
      className="w-full relative py-20 sm:pt-[120px] overflow-clip bg-white"
      id="Ecosystem"
    >
      <div className="container mx-auto">
        <div>
          <div className="h-full fade-in-up">
            <p className="support_text_medium text-gray-800 mb-4 uppercase">
              PRODUCTS & Deployed networks
            </p>
          </div>
        </div>
        <div>
          <div className="h-full fade-in-up">
            <h2 className="heading2_semibold text-gray-800 mb-12 lg:text-[60px]">
              Explore the <br className="hidden sm:block" />
              Meridian Ecosystem
            </h2>
          </div>
        </div>
        <div className="flex flex-wrap gap-2 mb-4">
          {PlatformData.map((item, index) => (
            <button
              type="button"
              className={cn(
                "py-2 px-3 text-[15px] rounded-lg hover:bg-gray-200 duration-200",
                {
                  "font-semibold bg-gray-200": active?.title === item.title,
                }
              )}
              onClick={() => setActive(item)}
              key={item.id}
            >
              {item.title}
            </button>
          ))}
        </div>
        <TabContent tabData={active} />
      </div>
    </section>
  );
};

const TabContent = ({ tabData }) => {
  const { title, tabs, potentials } = tabData || {};
  const [activeTab, setActiveTab] = React.useState(tabs ? tabs[0] : null);
  const [sliders, setSliders] = React.useState(potentials || []);
  const [swiper, setSwiper] = React.useState(null);

  const prevSlide = () => {
    swiper?.slidePrev();
  };

  const nextSlide = () => {
    swiper?.slideNext();
  };

  React.useEffect(() => {
    if (tabData) {
      if (activeTab?.title === "All") {
        setSliders(tabData.potentials);
      } else {
        setSliders(
          tabData.potentials.filter((p) => p.category === activeTab.title)
        );
      }
    }
  }, [tabData, activeTab]);

  return (
    <div className="py-20 md:py-[104px] rounded-xl bg-gray-200">
      <div className="flex flex-wrap gap-y-10 relative">
        <div className="w-full xl:max-w-[480px] px-4 sm:px-6 lg:px-8 xl:px-12">
          <h4 className="heading5_semibold xl:text-[26px] mb-6">{title}</h4>
          <div className="flex flex-wrap gap-2 mb-4">
            {tabs?.map((item, index) => (
              <button
                type="button"
                className={cn(
                  "py-2 px-3 text-[15px] rounded-lg hover:bg-[#A5ABB1] duration-200 hover:bg-opacity-[.32]",
                  {
                    "font-semibold bg-[#A5ABB1] bg-opacity-[.32]":
                      activeTab?.title === item.title,
                  }
                )}
                onClick={() => setActiveTab(item)}
                key={index}
              >
                {item.title}
              </button>
            ))}
          </div>
          <div className="max-w-[340px] text-[#606264]">
            {activeTab?.subtitle || ""}
          </div>
        </div>
        <div className="w-full xl:w-0 flex-grow pb-5 overflow-hidden">
          <motion.div className="px-4 sm:px-6 lg:pr-0 lg:pl-7">
            <Swiper
              onSwiper={setSwiper}
              className="overflow-visible lg:pr-7"
              {...{
                slidesPerView: 1.2,
                spaceBetween: 30,
                loop: false,
                autoplay: {
                  delay: 2500,
                  disableOnInteraction: false,
                },
                breakpoints: {
                  640: {
                    slidesPerView: 1.8,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 2.2,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 2.3,
                    spaceBetween: 30,
                  },
                  1280: {
                    slidesPerView: 2.3,
                    spaceBetween: 40,
                  },
                  1440: {
                    slidesPerView: 2.4,
                    spaceBetween: 40,
                  },
                },
              }}
            >
              {sliders.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="relative p-6 w-full min-h-[300px] md:min-h-[432px] flex flex-col justify-end rounded-lg items-baseline overflow-clip bg-white shadow-card">
                    <div className="absolute top-3 right-3">
                      {item.networks.map((network, index) => (
                        <img key={index} src={network} className="size-7 mt-2" />
                      ))}
                    </div>
                    <img
                      src={item.img}
                      alt=""
                      className="max-w-full mb-4 max-h-[110px]"
                    />
                    <div className="relative z-10 text-left sm:pl-2 mt-auto">
                      <h6 className="heading6_semibold text-dark-gray-800 mb-2">
                        {item.title}
                      </h6>
                      <p className="paragraph2 text-gray-400 text-[13px] max-w-[320px] mb-2 tracking-[0.2px]">
                        {item.subtitle}
                      </p>
                      <a
                        href={item.Link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn w-full"
                      >
                        {item.button}
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </motion.div>
        </div>
        {sliders.length > 0 && (
          <div className="flex justify-end absolute top-full left-4 sm:left-6 lg:left-12 gap-3">
            <PrevSlide color="white" onClick={prevSlide} />
            <NextSlide color="white" onClick={nextSlide} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DefiPotentials;
