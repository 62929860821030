import React from "react";

const PrevSlide = ({ color, ...rest }) => {
	return (
		<button type="button" {...rest}>
			<svg
				width="48"
				height="48"
				viewBox="0 0 48 48"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24Z"
					fill={color ? color : "#F2F5F8"}
				/>
				<path
					d="M28.708 14.7079C28.8009 14.615 28.8746 14.5047 28.9249 14.3833C28.9752 14.2619 29.001 14.1318 29.001 14.0004C29.001 13.869 28.9752 13.7389 28.9249 13.6175C28.8746 13.4961 28.8009 13.3858 28.708 13.2929C28.6151 13.2 28.5048 13.1263 28.3834 13.076C28.262 13.0258 28.1319 12.9999 28.0005 12.9999C27.8691 12.9999 27.739 13.0258 27.6176 13.076C27.4962 13.1263 27.3859 13.2 27.293 13.2929L17.293 23.2929C17.2 23.3858 17.1263 23.4961 17.0759 23.6175C17.0256 23.7389 16.9997 23.869 16.9997 24.0004C16.9997 24.1318 17.0256 24.262 17.0759 24.3834C17.1263 24.5048 17.2 24.6151 17.293 24.7079L27.293 34.7079C27.4806 34.8956 27.7351 35.001 28.0005 35.001C28.2659 35.001 28.5203 34.8956 28.708 34.7079C28.8956 34.5203 29.001 34.2658 29.001 34.0004C29.001 33.7351 28.8956 33.4806 28.708 33.2929L19.4142 24.0004L28.708 14.7079Z"
					fill="currentColor"
				/>
			</svg>
		</button>
	);
};

export default PrevSlide;
