import React from "react";

const ReadBetweenLines = () => {
	return (
		<section
			className="w-full relative py-20 bg-home-gradient sm:min-h-screen overflow-clip bg-white"
			id="Blog"
		>
			<img
				src="/img/background_2.png"
				alt="pattern"
				loading="lazy"
				className="w-full absolute left-0 top-0 pointer-events-none"
			/>
			<div className="container mx-auto text-center relative z-10">
				<p className="support_text_medium text-gray-200 mb-4 uppercase text-left">
					MEDIUM Blog
				</p>
				<h2 className="heading2_semibold text-gray-200 mb-12 text-left lg:text-[60px]">
					Browse our <br />Latest Developments
				</h2>
				<div className="flex sm:grid overflow-x-auto scroll-hide gap-6 sm:grid-cols-12 mb-10">
					<div className="sm:col-span-9">
						<div className="flex items-start justify-between gap-8 p-6 bg-primary-deepBlue rounded-lg w-[320px] sm:w-full">
							<div className="flex flex-col sm:flex-row sm:gap-8 sm:grow sm:justify-between">
								<div className="flex flex-col items-start justify-between col-span-4 text-left order-2 sm:order-1">
									<div className="mb-10 sm:mb-0">
										<p className="support_text_medium uppercase text-gray-200 mb-4">
											Developer
										</p>
										<div className="subheading text-gray-200 pr-[10%] sm:pr-0">
											<p className="subheading text-gray-200 pr-[10%] sm:pr-0">
												Meridian 1st anniversary: Meridian Ventures Yearly Letter & Future Roadmap
											</p>
										</div>
									</div>
									<a
										href="https://medium.com/@meridianFinance/meridian-1st-anniversary-meridian-ventures-yearly-letter-future-roadmap-13988bf7ebbc"
										target="-blank"
										className="support_text_medium underline uppercase text-gray-200"
									>
										Read More
									</a>
								</div>
								<div className="order-1 sm:order-2 sm:grow max-w-[700px]">
									<img
										src="/img/read-blog-1.png"
										alt=""
										className="rounded-lg h-full object-contain"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="sm:col-span-3">
						<div className="p-6 bg-primary-deepBlue rounded-lg h-full flex flex-col items-start w-[320px] sm:w-auto justify-between text-left">
							<div>
								<img
									src="/img/read-blog-2.png"
									alt=""
									className="w-full mb-6 rounded-lg"
								/>
							</div>
							<div>
								<p className="support_text_medium uppercase text-gray-200 mb-4">
									Investing
								</p>
								<p className="subheading text-gray-200 pb-6">
									Unlocking your Liquidity Potential with Meridian.
								</p>
								<a
									href="https://medium.com/@meridianFinance/unlocking-your-liquidity-potential-with-meridian-5b23cd5d0c69"
									target="-blank"
									className="support_text_medium underline uppercase text-white"
								>
									Read More
								</a>
							</div>
						</div>
					</div>
				</div>
				<a href="https://medium.com/@meridianFinance" className="btn" target="-blank">
					Go To Blogs
				</a>
			</div>
		</section>
	);
};

export default ReadBetweenLines;
