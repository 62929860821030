import React from "react";
import { Icon1, Icon2 } from "../../../components/Icons";

const Governence = () => {
  return (
    <section
      className="w-full relative py-20 sm:pt-20 sm:pb-[175px] overflow-clip bg-white"
      id="Governance"
    >
      <div className="container mx-auto">
        <div className="grid grid-cols-1 sm:gap-6 sm:grid-cols-12">
          <div className="col-span-7">
            <div>
              <div className="h-full fade-in-up">
                <p className="support_text_medium text-gray-800 mb-4 uppercase">
                  {" "}
                  Governance{" "}
                </p>
              </div>
            </div>
            <div>
              <div className="h-full fade-in-up">
                <h2 className="heading2_semibold text-gray-800 mb-4 lg:text-[60px]">
                  {" "}
                  Governed and Shaped <br className="hidden sm:block" /> by the
                  Community{" "}
                </h2>
              </div>
            </div>
            <div>
              <div className="h-full fade-in-up">
                <p className="subheading text-black sm:text-black-[66%] mb-5 sm:mb-12">
                  {" "}
                  The Meridian protocol is fully decentralized and governed by
                  <br className="hidden sm:block" /> MST tokenholders.
                </p>
              </div>
            </div>
            <div>
              <div className="h-full fade-in-up">
                <a
                  href="https://snapshot.org/#/meridian-foundation.eth"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="group inline-block mb-10 sm:mb-0"
                >
                  <button className="btn"> Join Governance Community </button>
                </a>
              </div>
            </div>
          </div>
          <div className="col-span-5">
            <div>
              <div className="h-full fade-in-up">
                <div className="p-6 rounded-lg bg-gray-200 mb-4">
                  <div className="flex items-start justify-between">
                    <p className="support_text_medium uppercase mb-24 text-gray-800 whitespace-pre-wrap">
                      Chosen technological & business company{" "}
                    </p>
                    <a href="https://www.meridianventures.co.uk/" className="group" target="_blank">
                      {Icon1}
                    </a>
                  </div>
                  <p className="subheading text-gray-800 whitespace-pre-wrap">
                    Meridian is developed by{" "}
                    <span className="font-semibold">Meridian Ventures LTD</span>
                    , a UK registered development company.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="h-full fade-in-up">
                <div className="p-6 rounded-lg bg-primary-deepBlue mb-4">
                  <div className="flex items-start justify-between">
                    <p className="support_text_medium uppercase mb-24 text-gray-200 whitespace-pre-wrap">
                      On-chain staking{" "}
                    </p>
                    <a href="https://stake.meridianfinance.net/" className="group"  target="_blank">
                      {Icon2}
                    </a>
                  </div>
                  <p className="subheading text-gray-200 whitespace-pre-wrap">
                    Earn protocol fees by staking MST tokens{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Governence;
