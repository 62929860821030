//get data from api
export const getUSDMData = async (
  setUSDMData,
  setIsLoading,
  setETHMData,
  setUSDTData,
  setUSDCData
) => {
  const mintData = await fetch(
    "https://omnidex.bmaa3ajd1gjri.eu-west-2.cs.amazonlightsail.com/lending_yields"
  );

  const data = await mintData.json();
  console.log(data)

  //set USDM data
  const updatedUSDMData = [
    {
      icon: "/assets/yields/telos-evm.svg",
      title: "Lending Pool",
      subTitle: "TelosEVM",
      percentage: `${Number(data?.telos?.USDM.total_deposit_yield).toFixed(2) || 0}%`,
      color: "#8273FF",
      appPath: "https://mint.meridianfinance.net/#/",
    },
    {
      icon: "/assets/yields/fuse.svg",
      title: "Lending Pool",
      subTitle: "Fuse",
      percentage: `${Number(data?.fuse?.USDM.total_deposit_yield).toFixed(2) || 0}%`,
      color: "#28C76F",
      appPath: "https://mint.meridianfinance.net/#/",
    },
    
  ];

  // Sort data by percentage descending
  updatedUSDMData.sort(
    (a, b) => parseFloat(b.percentage) - parseFloat(a.percentage)
  );
  setUSDMData(updatedUSDMData);

  //set ETHM data
  const updatedETHMData = [
    {
      icon: "/assets/yields/telos-evm.svg",
      title: "Lending Pool",
      subTitle: "TelosEVM",
      percentage: `${Number(data?.telos?.WETH?.total_deposit_yield).toFixed(2) || 0}%`,
      color: "#8273FF",
      appPath: "https://lend.meridianfinance.net/markets",
    },
    {
      icon: "/assets/yields/fuse.svg",
      title: "Lending Pool",
      subTitle: "Fuse",
      percentage: `${Number(data?.fuse?.WETH?.total_deposit_yield).toFixed(2) || 0}%`,
      color: "#28C76F",
      appPath: "https://lend.meridianfinance.net/markets",
    },
    {
      icon: "/assets/yields/meter.svg",
      title: "Lending Pool",
      subTitle: "Meter",
      percentage: `${Number(data?.meter?.WETH?.total_deposit_yield).toFixed(2) || 0}%`,
      color: "#685B72",
      appPath: "https://lend.meridianfinance.net/markets",
    },
    {
      icon: "/assets/yields/taiko.svg",
      title: "Lending Pool",
      subTitle: "Taiko",
      percentage: `${Number(data?.taiko?.WETH?.total_deposit_yield).toFixed(2) || 0}%`,
      color: "#685B72",
      appPath: "https://lend.meridianfinance.net/markets",
    },
  ];
  // Sort data by percentage descending
  updatedETHMData.sort(
    (a, b) => parseFloat(b.percentage) - parseFloat(a.percentage)
  );
  setETHMData(updatedETHMData);

  //set USDT data
  const updatedUSDTData = [
    {
      icon: "/assets/yields/telos-evm.svg",
      title: "Lending Pool",
      subTitle: "TelosEVM",
      percentage: `${Number(data?.telos?.USDT?.total_deposit_yield).toFixed(2) || 0}%`,
      color: "#8273FF",
      appPath: "https://lend.meridianfinance.net/markets",
    },
    {
      icon: "/assets/yields/fuse.svg",
      title: "Lending Pool",
      subTitle: "Fuse",
      percentage: `${Number(data?.fuse?.USDT?.total_deposit_yield).toFixed(2) || 0}%`,
      color: "#28C76F",
      appPath: "https://lend.meridianfinance.net/markets",
    },
    {
      icon: "/assets/yields/meter.svg",
      title: "Lending Pool",
      subTitle: "Meter",
      percentage: `${Number(data?.meter?.USDT?.total_deposit_yield).toFixed(2) || 0}%`,
      color: "#685B72",
      appPath: "https://lend.meridianfinance.net/markets",
    },
    {
      icon: "/assets/yields/taiko.svg",
      title: "Lending Pool",
      subTitle: "Taiko",
      percentage: `${Number(data?.taiko?.USDT?.total_deposit_yield).toFixed(2) || 0}%`,
      color: "#685B72",
      appPath: "https://lend.meridianfinance.net/markets",
    },
  ];
  // Sort data by percentage descending
  updatedUSDTData.sort(
    (a, b) => parseFloat(b.percentage) - parseFloat(a.percentage)
  );
  setUSDTData(updatedUSDTData);

  //set USDC data
  console.log(data?.taiko?.TAIKO?.total_deposit_yield)
  const updatedUSDCData = [
    {
      icon: "/assets/yields/telos-evm.svg",
      title: "Lending Pool",
      subTitle: "TelosEVM",
      percentage: `${Number(data?.telos?.USDC?.total_deposit_yield).toFixed(2) || 0}%`,
      color: "#8273FF",
      appPath: "https://lend.meridianfinance.net/markets",
    },
    {
      icon: "/assets/yields/fuse.svg",
      title: "Lending Pool",
      subTitle: "Fuse",
      percentage: `${Number(data?.fuse?.USDC?.total_deposit_yield).toFixed(2) || 0}%`,
      color: "#28C76F",
      appPath: "https://lend.meridianfinance.net/markets",
    },
    {
      icon: "/assets/yields/meter.svg",
      title: "Lending Pool",
      subTitle: "Meter",
      percentage: `${Number(data?.meter?.USDC?.total_deposit_yield).toFixed(2) || 0}%`,
      color: "#685B72",
      appPath: "https://lend.meridianfinance.net/markets",
    },
    {
      icon: "/assets/yields/taiko.svg",
      title: "Lending Pool",
      subTitle: "Taiko",
      percentage: `${Number(data?.taiko?.USDCe?.total_deposit_yield).toFixed(2) || 0}%`,
      color: "#685B72",
      appPath: "https://lend.meridianfinance.net/markets",
    },
  ];
  // Sort data by percentage descending
  updatedUSDCData.sort(
    (a, b) => parseFloat(b.percentage) - parseFloat(a.percentage)
  );
  setUSDCData(updatedUSDCData);

  setIsLoading(false);
};
