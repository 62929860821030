import React, { useEffect, useState } from "react";
import { caretdown2, ellipsis } from "../../../components/Icons";
import { cn } from "../../../utils/cn";
import { getMintData } from "../../../lib/yields/mint";
import { getUSDMData } from "../../../lib/yields/yields";

const Funds = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([
    {
      title: "Stability Pool",
      subtitle: "TelosEVM",
      img: "/img/funds/1.svg",
      progress: "0%",
      bg: "#8273FF",
    },
    {
      title: "Stability Pool",
      subtitle: "Fuse",
      img: "/img/funds/2.svg",
      progress: "0%",
      bg: "#28C76F",
    },
    {
      title: "Stability Pool",
      subtitle: "Base",
      img: "/img/funds/3.svg",
      progress: "0%",
      bg: "#60C3FB",
    },
    {
      title: "Stability Pool",
      subtitle: "Meter",
      img: "/img/funds/4.svg",
      progress: "0%",
      bg: "#685B72",
    },
  ]);
  const [mintData, setMintData] = useState([]);
  const [isUSDLoading, setIsUSDLoading] = useState(true);
  const [USDMData, setUSDMData] = useState([]);
  const [ETHData, setETHMData] = useState([]);
  const [USDTData, setUSDTData] = useState([]);
  const [USDCData, setUSDCData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getUSDMData(
          setUSDMData,
          setIsUSDLoading,
          setETHMData,
          setUSDTData,
          setUSDCData
        );
        await getMintData(updateData, setIsLoading);
      } catch (error) {
        console.error("Error fetching mint data:", error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const updateData = (mintData) => {
    setData((prevData) =>
      prevData.map((item) => {
        const matchingMintData = mintData.find(
          (mintItem) => mintItem.subTitle === item.subtitle
        );
        return matchingMintData
          ? { ...item, progress: matchingMintData.percentage }
          : item;
      })
    );
  };

  const calculateAverageYield = () => {
    const validPercentages = data
      .map((item) => parseFloat(item.progress))
      .filter((percentage) => !isNaN(percentage));

    if (validPercentages.length === 0) return "0%";

    const average =
      validPercentages.reduce((sum, percentage) => sum + percentage, 0) /
      validPercentages.length;
    return `${average.toFixed(2)}%`;
  };
  if (isLoading) {
    return <div>Loading...</div>;
  }
  const assets = ["ETH", "USDT", "USDC", "USDM"];

  return (
    <section
      className="w-full relative py-20 sm:py-[120px] overflow-clip bg-white"
      id="Yields"
    >
      <div className="container mx-auto max-w-[1056px]">
        <div className="sm:text-center">
          <div>
            <div className="h-full fade-in-up">
              <p className="support_text_medium text-gray-800 mb-4 uppercase">
                yields
              </p>
            </div>
          </div>
          <div>
            <div className="h-full fade-in-up">
              <h2 className="heading2_semibold text-gray-800 mb-[70px] lg:text-[60px]">
                Manage and Maximize <br className="hidden sm:block" /> Your
                Funds
              </h2>
            </div>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-20">
            {[ETHData, USDTData, USDCData, USDMData].map((DataItem, index) => {
              return (
                <div key={index}>
                  <div className="flex justify-between items-center mb-6 ">
                    <div>
                      <h6 className="subheading3">
                        Meridian Lend - {assets[index]} Yields
                      </h6>
                      <p className="text-sm text-gray-400">
                        Average {calculateAverageYield()}
                      </p>
                    </div>
                    <button type="button">{ellipsis}</button>
                  </div>
                  <div>
                    {DataItem.map((item, index) => {
                      const heighest = Number(
                        DataItem[0].percentage.replace("%", "")
                      ); // will be the 100%
                      if (item.percentage[0] == "0") return null;
                      return (
                        <div
                          className="flex items-center gap-2 sm:gap-4 mt-4"
                          key={index}
                        >
                          <div className="w-[170px] flex flex-wrap gap-4">
                            <img
                              src={item.icon}
                              className="w-[34px]"
                              alt={item.icon}
                            />
                            <div className="w-0 flex-grow">
                              <h6 className="text-[15px] font-medium">
                                {item.title}
                              </h6>
                              <p className="text-[13px] text-gray-400">
                                {item.subTitle}
                              </p>
                            </div>
                          </div>
                          <div className="w-0 flex-grow flex items-center gap-2 sm:gap-4 text-gray-400 text-[15px]">
                            <span className="w-0 flex-grow rounded-full bg-dark bg-opacity-[0.08]">
                              <div
                                className={cn(`h-2 rounded-full`)}
                                style={{
                                  background: item.color,
                                  width:
                                  ( Number(item.percentage.replace("%", "")) / heighest)*100 + "%",
                                }}
                              ></div>
                            </span>
                            <span className="block leading-[1]">
                              {item.percentage}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div></div>
        </div>
        {/* <div className="flex justify-center mt-6 md:mt-12">
          <a href="" className="btn border-0 flex items-center gap-1">
            {caretdown2} <span>Expand</span>
          </a>
        </div> */}
      </div>
    </section>
  );
};

export default Funds;
