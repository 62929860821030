export const Github = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="w-10 h-10 sm:h-6 sm:w-6"
	>
		<path
			d="M11.9979 1.2002C6.03492 1.2002 1.19922 6.158 1.19922 12.2739C1.19679 14.595 1.90867 16.8582 3.23426 18.7436C4.55984 20.6291 6.4321 22.0414 8.58642 22.781C9.12642 22.883 9.32352 22.5411 9.32352 22.2474C9.32352 21.9841 9.31407 21.2879 9.30867 20.3641C6.30492 21.0328 5.67042 18.8793 5.67042 18.8793C5.18037 17.5998 4.47162 17.2593 4.47162 17.2593C3.49017 16.5727 4.54452 16.5865 4.54452 16.5865C5.62857 16.6651 6.19827 17.7281 6.19827 17.7281C7.16217 19.4197 8.72682 18.9317 9.34242 18.6476C9.43962 17.9321 9.71907 17.444 10.0282 17.1669C7.63062 16.887 5.10882 15.9371 5.10882 11.6949C5.10882 10.4857 5.53002 9.49721 6.22122 8.72238C6.10917 8.44251 5.73927 7.31611 6.32652 5.79265C6.32652 5.79265 7.23372 5.49485 9.29652 6.92732C10.1777 6.68137 11.0871 6.55619 12.0006 6.55507C12.9141 6.55692 13.8234 6.68256 14.7046 6.92869C16.7661 5.49623 17.6719 5.79402 17.6719 5.79402C18.2605 7.31887 17.8906 8.44389 17.7799 8.72376C18.4725 9.49859 18.8896 10.4871 18.8896 11.6962C18.8896 15.9495 16.3651 16.8857 13.9594 17.16C14.3469 17.5019 14.6925 18.1775 14.6925 19.2102C14.6925 20.6909 14.679 21.8848 14.679 22.2474C14.679 22.5439 14.8734 22.8885 15.4215 22.7796C17.5737 22.0383 19.4436 20.6254 20.7674 18.7404C22.0911 16.8555 22.8018 14.5936 22.7992 12.2739C22.7992 6.158 17.9635 1.2002 11.9979 1.2002Z"
			fill="white"
		></path>
	</svg>
);
export const X = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="w-10 h-10 sm:h-6 sm:w-6"
	>
		<path
			d="M12.0031 22.8002C17.9678 22.8002 22.8031 17.9649 22.8031 12.0002C22.8031 6.03552 17.9678 1.2002 12.0031 1.2002C6.03845 1.2002 1.20312 6.03552 1.20312 12.0002C1.20312 17.9649 6.03845 22.8002 12.0031 22.8002Z"
			fill="white"
		></path>
		<g clipPath="url(#x-twitter_svg__clip0_3180_13284)">
			<path
				d="M15.95 6.5625H17.7905L13.7705 11.169L18.5 17.4375H14.7972L11.897 13.635L8.57825 17.4375H6.737L11.0367 12.51L6.5 6.5625H10.2973L12.9185 10.0373L15.95 6.5625ZM15.305 16.3335H16.325L9.74225 7.60875H8.64875L15.305 16.3335Z"
				fill="black"
			></path>
		</g>
		<defs>
			<clipPath id="x-twitter_svg__clip0_3180_13284">
				<rect
					width="12"
					height="12"
					fill="white"
					transform="translate(6.5 6)"
				></rect>
			</clipPath>
		</defs>
	</svg>
);
export const Discord = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="w-10 h-10 sm:h-6 sm:w-6"
	>
		<path
			d="M22.7992 12.0002C22.7992 6.03552 17.9639 1.2002 11.9992 1.2002C6.03454 1.2002 1.19922 6.03552 1.19922 12.0002C1.19922 17.9649 6.03454 22.8002 11.9992 22.8002C17.9639 22.8002 22.7992 17.9649 22.7992 12.0002Z"
			fill="white"
		></path>
		<path
			d="M17.4173 8.30095C16.4265 7.50827 15.2375 7.11196 13.9824 7.0459L13.7843 7.24408C14.9072 7.50827 15.8981 8.03671 16.8228 8.76332C15.6999 8.16883 14.4448 7.77251 13.1237 7.64039C12.7274 7.57433 12.3971 7.57433 12.0008 7.57433C11.6045 7.57433 11.2742 7.57433 10.8779 7.64039C9.55674 7.77251 8.30169 8.16883 7.17876 8.76332C8.10351 8.03671 9.09437 7.50827 10.2173 7.24408L10.0191 7.0459C8.76407 7.11196 7.57508 7.50827 6.58427 8.30095C5.46133 10.4147 4.86684 12.7927 4.80078 15.2367C5.79159 16.2936 7.17876 16.9542 8.63199 16.9542C8.63199 16.9542 9.09437 16.4257 9.42462 15.9633C8.56593 15.7652 7.77326 15.3028 7.24482 14.5762C7.7072 14.8404 8.16957 15.1046 8.63199 15.3028C9.22649 15.567 9.82098 15.6991 10.4155 15.8313C10.9439 15.8973 11.4723 15.9633 12.0008 15.9633C12.5292 15.9633 13.0577 15.8973 13.5861 15.8313C14.1806 15.6991 14.7751 15.567 15.3696 15.3028C15.832 15.1046 16.2944 14.8404 16.7567 14.5762C16.2283 15.3028 15.4356 15.7652 14.5769 15.9633C14.9072 16.4257 15.3696 16.9542 15.3696 16.9542C16.8228 16.9542 18.21 16.2936 19.2008 15.2367C19.1347 12.7927 18.5402 10.4147 17.4173 8.30095ZM9.82098 14.0477C9.16043 14.0477 8.56593 13.4532 8.56593 12.7267C8.56593 12 9.16043 11.4055 9.82098 11.4055C10.4815 11.4055 11.076 12 11.076 12.7267C11.076 13.4532 10.4815 14.0477 9.82098 14.0477ZM14.1806 14.0477C13.52 14.0477 12.9255 13.4532 12.9255 12.7267C12.9255 12 13.52 11.4055 14.1806 11.4055C14.8411 11.4055 15.4356 12 15.4356 12.7267C15.4356 13.4532 14.8411 14.0477 14.1806 14.0477Z"
			fill="black"
		></path>
	</svg>
);
export const Mail = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="w-10 h-10 sm:h-6 sm:w-6"
	>
		<path
			d="M22.7992 12.0002C22.7992 6.03552 17.9639 1.2002 11.9992 1.2002C6.03454 1.2002 1.19922 6.03552 1.19922 12.0002C1.19922 17.9649 6.03454 22.8002 11.9992 22.8002C17.9639 22.8002 22.7992 17.9649 22.7992 12.0002Z"
			fill="white"
		></path>
		<path
			d="M7.2 16.5C6.87 16.5 6.5876 16.3899 6.3528 16.1698C6.118 15.9497 6.0004 15.6847 6 15.375V8.625C6 8.31562 6.1176 8.05087 6.3528 7.83075C6.588 7.61062 6.8704 7.50037 7.2 7.5H16.8C17.13 7.5 17.4126 7.61025 17.6478 7.83075C17.883 8.05125 18.0004 8.316 18 8.625V15.375C18 15.6844 17.8826 15.9493 17.6478 16.1698C17.413 16.3903 17.1304 16.5004 16.8 16.5H7.2ZM12 12.5625L16.8 9.75V8.625L12 11.4375L7.2 8.625V9.75L12 12.5625Z"
			fill="black"
		></path>
	</svg>
);
export const caretdown = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth={1.5}
		stroke="currentColor"
		className="size-5"
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="m19.5 8.25-7.5 7.5-7.5-7.5"
		/>
	</svg>
);
export const Icon1 = (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#arrow-up-right-black_svg__clip0_3180_13088)">
			<path
				d="M27.3316 27.3132C26.0889 28.5559 24.7156 29.5512 23.2116 30.2992C21.7342 31.0361 20.1513 31.5388 18.5196 31.7892C15.2572 32.2948 11.9184 31.7742 8.96489 30.2992C7.46089 29.5512 6.08756 28.5559 4.84489 27.3125C3.60156 26.0699 2.60822 24.6999 1.86422 23.1999C0.383689 20.2453 -0.139223 16.9028 0.368225 13.6372C0.618615 12.0052 1.12129 10.4221 1.85822 8.94455C2.60622 7.44122 3.60156 6.06788 4.84489 4.82455C6.08756 3.58188 7.45822 2.58855 8.95822 1.84455C10.435 1.10518 12.0187 0.602387 13.6516 0.354551C15.2756 0.101217 16.9002 0.101217 18.5249 0.354551C20.1577 0.602398 21.7415 1.10519 23.2182 1.84455C24.7182 2.58855 26.0889 3.58188 27.3316 4.82455C28.5742 6.06788 29.5702 7.44122 30.3182 8.94455C31.0544 10.4198 31.5552 12.0011 31.8022 13.6312C32.0555 15.246 32.0555 16.8904 31.8022 18.5052C31.5544 20.138 31.0516 21.7218 30.3122 23.1985C29.5682 24.6985 28.5742 26.0692 27.3322 27.3119L27.3316 27.3132ZM20.7349 20.5272L22.1082 20.5212V10.0499H11.6349L11.6296 11.4232L19.7582 11.4172L9.58956 21.5865L10.5716 22.5685L20.7402 12.3999L20.7336 20.5285L20.7349 20.5272Z"
				fill="#0C1725"
			></path>
		</g>
		<defs>
			<clipPath id="arrow-up-right-black_svg__clip0_3180_13088">
				<rect width="32" height="32" fill="white"></rect>
			</clipPath>
		</defs>
	</svg>
);
export const Icon2 = (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#arrow-up-right-white_svg__clip0_3180_13094)">
			<path
				d="M27.3316 27.3132C26.0889 28.5559 24.7156 29.5512 23.2116 30.2992C21.7342 31.0361 20.1513 31.5388 18.5196 31.7892C15.2572 32.2948 11.9184 31.7742 8.96489 30.2992C7.46089 29.5512 6.08756 28.5559 4.84489 27.3125C3.60156 26.0699 2.60822 24.6999 1.86422 23.1999C0.383689 20.2453 -0.139223 16.9028 0.368225 13.6372C0.618615 12.0052 1.12129 10.4221 1.85822 8.94455C2.60622 7.44122 3.60156 6.06788 4.84489 4.82455C6.08756 3.58188 7.45822 2.58855 8.95822 1.84455C10.435 1.10518 12.0187 0.602387 13.6516 0.354551C15.2756 0.101217 16.9002 0.101217 18.5249 0.354551C20.1577 0.602398 21.7415 1.10519 23.2182 1.84455C24.7182 2.58855 26.0889 3.58188 27.3316 4.82455C28.5742 6.06788 29.5702 7.44122 30.3182 8.94455C31.0544 10.4198 31.5552 12.0011 31.8022 13.6312C32.0555 15.246 32.0555 16.8904 31.8022 18.5052C31.5544 20.138 31.0516 21.7218 30.3122 23.1985C29.5682 24.6985 28.5742 26.0692 27.3322 27.3119L27.3316 27.3132ZM20.7349 20.5272L22.1082 20.5212V10.0499H11.6349L11.6296 11.4232L19.7582 11.4172L9.58956 21.5865L10.5716 22.5685L20.7402 12.3999L20.7336 20.5285L20.7349 20.5272Z"
				fill="white"
			></path>
		</g>
		<defs>
			<clipPath id="arrow-up-right-white_svg__clip0_3180_13094">
				<rect width="32" height="32" fill="white"></rect>
			</clipPath>
		</defs>
	</svg>
);
export const ellipsis = (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle
			cx="10.9997"
			cy="10.9997"
			r="0.916667"
			stroke="#4B465C"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle
			cx="10.9997"
			cy="10.9997"
			r="0.916667"
			stroke="white"
			strokeOpacity="0.5"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle
			cx="10.9997"
			cy="17.4167"
			r="0.916667"
			stroke="#4B465C"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle
			cx="10.9997"
			cy="17.4167"
			r="0.916667"
			stroke="white"
			strokeOpacity="0.5"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<ellipse
			cx="10.9997"
			cy="4.58366"
			rx="0.916667"
			ry="0.916667"
			stroke="#4B465C"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<ellipse
			cx="10.9997"
			cy="4.58366"
			rx="0.916667"
			ry="0.916667"
			stroke="white"
			strokeOpacity="0.5"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export const angleRight = (
	<svg
		width="9"
		height="16"
		viewBox="0 0 9 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0.485313 14.7578C0.417746 14.8254 0.364148 14.9056 0.327581 14.9939C0.291014 15.0822 0.272192 15.1768 0.272192 15.2724C0.272192 15.3679 0.291014 15.4625 0.327581 15.5508C0.364148 15.6391 0.417746 15.7193 0.485313 15.7869C0.55288 15.8544 0.633096 15.908 0.721376 15.9446C0.809658 15.9812 0.904278 16 0.999833 16C1.09539 16 1.19001 15.9812 1.27829 15.9446C1.36657 15.908 1.44678 15.8544 1.51435 15.7869L8.78671 8.51452C8.85433 8.44698 8.90797 8.36677 8.94456 8.27849C8.98116 8.1902 9 8.09557 9 8C9 7.90443 8.98116 7.8098 8.94456 7.72151C8.90797 7.63323 8.85433 7.55302 8.78671 7.48548L1.51435 0.213121C1.37789 0.0766618 1.19281 -3.80414e-09 0.999833 0C0.80685 3.80414e-09 0.621772 0.0766618 0.485313 0.213121C0.348854 0.34958 0.272192 0.534658 0.272192 0.72764C0.272192 0.920622 0.348854 1.1057 0.485313 1.24216L7.24406 8L0.485313 14.7578Z"
			fill="#A4AAB0"
		></path>
	</svg>
);
export const hamburger = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth={1.5}
		stroke="currentColor"
		className="size-6 sm:size-7 md:size-8"
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
		/>
	</svg>
);
export const close = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth={1.5}
		stroke="currentColor"
		className="size-6 sm:size-7 md:size-8"
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M6 18 18 6M6 6l12 12"
		/>
	</svg>
);
export const caretdown2 = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth={1.5}
		stroke="currentColor"
		className="size-4"
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
		/>
	</svg>
);
