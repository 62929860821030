import React from "react";
import Layout from "../../components/Layout";
import Banner from "./component/Banner";
import Collaborators from "./component/Collaborators";
import DefiPotentials from "./component/DefiPotentials";
import ExploreDefi from "./component/ExploreDefi";
import Funds from "./component/Funds";
import Governence from "./component/Governence";
import Invest from "./component/Invest";
import ReadBetweenLines from "./component/ReadBetweenLines";

const Home = () => {
	return (
		<Layout>
			<Banner />
			<DefiPotentials />
			<ExploreDefi />
			<Invest />
			<Funds />
			<Collaborators />
			<ReadBetweenLines />
			<Governence />
		</Layout>
	);
};

export default Home;
