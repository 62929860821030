import React from "react";
import "swiper/css/bundle";
import { Swiper, SwiperSlide } from "swiper/react";
import NextSlide from "../../../components/NextSlide";
import PrevSlide from "../../../components/PrevSlide";

const Collaborators = () => {
	const [swiper, setSwiper] = React.useState(null);
	const prevSlide = () => {
		swiper.slidePrev();
	};
	const nextSlide = () => {
		swiper.slideNext();
	};

	return (
		<section
			className="w-full relative sm:pt-20 pb-24 sm:pb-[120px] overflow-clip bg-white"
			id="Docs"
		>
			<div className="container mx-auto">
				<div>
					<div className="h-full fade-in-up">
						<p className="support_text_medium text-gray-800 mb-4 uppercase">
							Collaborations
						</p>
					</div>
				</div>
				<div>
					<div className="h-full fade-in-up">
						<h2 className="heading2_semibold text-gray-800 mb-12 lg:text-[60px]">
							Our Partners <br className="hidden sm:block" />
							Backing the <br className="hidden sm:block" />
							DeFi Evolution{" "}
						</h2>
					</div>
				</div>
				<div className="-translate-y-5 lg:translate-y-[-200%] mb-6 lg:-mb-12 flex items-center justify-end gap-4">
					<PrevSlide onClick={prevSlide} />
					<NextSlide onClick={nextSlide} />
				</div>
				<Swiper
					onSwiper={setSwiper}
					{...{
						slidesPerView: 1.2,
						spaceBetween: 30,
						loop: false,
						autoplay: {
							delay: 2500,
							disableOnInteraction: false,
						},
						breakpoints: {
							640: {
								slidesPerView: 2.2,
								spaceBetween: 30,
							},
							768: {
								slidesPerView: 2.4,
								spaceBetween: 30,
							},
							1024: {
								slidesPerView: 3.3,
								spaceBetween: 30,
							},
							1280: {
								slidesPerView: 3.3,
								spaceBetween: 40,
							},
						},
					}}
				>
					{collaborators.map((collaborator, index) => (
						<SwiperSlide key={index}>
							<div className="relative p-6 w-full h-[300px] md:h-[394px] flex flex-col justify-end rounded-lg items-baseline overflow-clip bg-gray-200">
								<img
									src={collaborator.img}
									alt=""
									className="left-8 top-8 absolute z-0 object-cover max-w-[60px]"
								/>
								<div className="relative z-10 text-left pl-2">
									<h6 className="heading6_semibold text-dark-gray-800">
										{collaborator.title}
									</h6>
									<p className="paragraph2 text-gray-400">
										{collaborator.description}
									</p>
								</div>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</section>
	);
};

const collaborators = [
	{
		img: "/img/collaborator/1.svg",
		title: "Telos Foundation",
		description: "Layer 0 & 1 Networks",
	},
	{
		img: "/img/collaborator/2.svg",
		title: "Artela Foundation",
		description: "EVM++ Layer 1 Network",
	},
	{
		img: "/img/collaborator/3.svg",
		title: "Fuse Labs",
		description: "Layer 1 Network",
	},
	{
		img: "/img/collaborator/4.svg",
		title: "Meter Foundation",
		description: "PoW & PoS Network",
	},
	{
		img: "/img/collaborator/5.png",
		title: "Taiko Foundation",
		description: "Layer 2 Network",
	},
	{
		img: "/img/collaborator/6.png",
		title: "Taraxa Foundation",
		description: "Layer 1 Network",
	},
];

export default Collaborators;
