//get data from api
export const getMintData = async (setMintData, setIsLoading) => {
  const mintData = await fetch(
    "https://omnidex.bmaa3ajd1gjri.eu-west-2.cs.amazonlightsail.com/stability_pool"
  );
  const data = await mintData.json();
  setIsLoading(false)
  const updatedMintData = [
    {
      icon: "/assets/yields/telos-evm.svg",
      title: "Stability Pool",
      subTitle: "TelosEVM",
      percentage: `${data.telos}%`,
      color: "#8273FF",
      appPath: "https://mint.meridianfinance.net/#/",
    },
    {
      icon: "/assets/yields/fuse.svg",
      title: "Stability Pool",
      subTitle: "Fuse",
      percentage: `${data.fuse}%`,
      color: "#28C76F",
      appPath: "https://mint.meridianfinance.net/#/",
    },
    {
      icon: "/assets/yields/base.svg",
      title: "Stability Pool",
      subTitle: "Base",
      percentage: `${data.base}%`,
      color: "#60C3FB",
      appPath: "https://mint.meridianfinance.net/#/",
    },
  ];

  // Sort data by percentage descending
  updatedMintData.sort(
    (a, b) => parseFloat(b.percentage) - parseFloat(a.percentage)
  );
  setMintData(updatedMintData);
};
