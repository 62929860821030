import React from "react";
import useScroll from "../hooks/useScroll";
import { cn } from "../utils/cn";
import { close, hamburger } from "./Icons";
import AppsModal from "../pages/home/component/modals/AppsModal";

const Header = () => {
  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const scroll = useScroll();
  return (
    <header
      className={cn("w-full fixed top-0 transition-all duration-200 z-50", {
        "bg-gray-800": scroll > 0,
      })}
    >
      {modalOpen && (
        <AppsModal
          isOpen={modalOpen}
          onClose={() => setModalOpen((prev) => !prev)}
        />
      )}
      <div
        className={cn(
          "w-full sm:px-6 flex justify-between items-center relative transition-all duration-200 ease-in-out px-4 sm:py-4 py-[28px]",
          {
            "py-4 sm:py-2": scroll > 0,
          }
        )}
      >
        <div className="relative w-[120px] sm:w-[140px] md:w-[200px]">
          <img src="/img/logo.svg" alt="" />
        </div>
        <div className="hidden lg:block">
          <Menu />
        </div>
        <div
          className={cn(
            "border-t border-white border-opacity-10 invisible opacity-0 -translate-y-4 lg:hidden w-full absolute top-full left-0 bg-gray-800 bg-opacity-[0.99] p-6 max-h-[calc(100vh-80px)] transition-all duration-200 ease-in-out overflow-y-auto",
            { "visible opacity-100 translate-y-0": open }
          )}
        >
          <div>
            <Menu />
          </div>
        </div>
        <button
          className="lg:hidden ml-auto text-white mr-4"
          type="button"
          onClick={() => setOpen(!open)}
        >
          {open ? close : hamburger}
        </button>
        <div className="p-1 rounded-full bg-linear-gradient-black flex">
          <button href="" onClick={()=>setModalOpen(prev=>!prev)} className="btn max-sm:text-xs max-sm:px-2 ">
            Explore Apps
          </button>
        </div>
      </div>
    </header>
  );
};
const Menu = () => {
  return (
    <nav className="flex lg:flex-row flex-col lg:items-center gap-5 xl:gap-10">
      {menu.map((item, index) => (
        <a
          href={item.url}
          target="-blank"
          key={index}
          className="paragraph text-[15px] text-white lg:text-[#717982] hover:text-primary-blue"
        >
          {item.name}
        </a>
      ))}
    </nav>
  );
};
const menu = [
  {
    name: "Ecosystem",
    url: "https://stake.meridianfinance.net/#/ecosystem",
  },
  {
    name: "Token Portal",
    url: "https://stake.meridianfinance.net/#/tokens",
  },
  {
    name: "Yields",
    url: "https://stake.meridianfinance.net/#/yields",
  },
  {
    name: "Staking",
    url: "https://stake.meridianfinance.net/#/stakemst",
  },
  {
    name: "Governance",
    url: "https://snapshot.org/#/meridian-foundation.eth",
  },
  {
    name: "Blog",
    url: "https://medium.com/@meridianFinance",
  },
  {
    name: "Docs",
    url: "https://docs.meridianfinance.net/",
  },
];

export default Header;
