import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import bannerVideo from "../../../lottie/animation.webm";
import { getMintData } from "../../../lib/yields/mint";
import { getUSDMData } from "../../../lib/yields/yields";

const Banner = () => {
	const [mintData, setMintData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [USDMData, setUSDMData] = useState([]);
	const [ETHData, setETHMData] = useState([]);
	const [USDTData, setUSDTData] = useState([]);
	const [USDCData, setUSDCData] = useState([]);
	const [apiValues, setApiValues] = useState({
		circulating_supply: "",
		mst_price: "",
		avg_staking_yield: "",
		unique_users: "",
	});
	const [forceLoading, setForceLoading] = useState(true); // For 2-second delay

	// Simulate a minimum 2-second loading time
	useEffect(() => {
		const timer = setTimeout(() => {
			setForceLoading(false);
		}, 1500); // 2 seconds

		return () => clearTimeout(timer); // Clear the timeout when component unmounts
	}, []);

	// Fetch mint and USDM data
	useEffect(() => {
		getMintData(setMintData, setIsLoading);
		getUSDMData(setUSDMData, setIsLoading, setETHMData, setUSDTData, setUSDCData);
	}, []);

	// Fetch API values for circulating supply, price, etc.
	useEffect(() => {
		const fetchApiValues = async () => {
			try {
				const response = await fetch(
					"https://omnidex.bmaa3ajd1gjri.eu-west-2.cs.amazonlightsail.com/landing_page"
				);
				const data = await response.json();

				const {
					circulating_supply,
					price: mst_price,
					total_revenue,
					total_mst_fees,
					unique_users,
				} = data;

				const formattedSupply = (circulating_supply / 1e18).toLocaleString("en-US", {
					maximumFractionDigits: 0,
				});

				const formattedPrice = Number(mst_price).toLocaleString("en-US", {
					style: "currency",
					currency: "USD",
					maximumFractionDigits: 3,
				});

				const formattedRevenue = Number(total_revenue).toLocaleString("en-US", {
					style: "currency",
					currency: "USD",
					maximumFractionDigits: 2,
				});

				const formattedFees = Number(total_mst_fees).toLocaleString("en-US", {
					style: "currency",
					currency: "USD",
					maximumFractionDigits: 2,
				});

				const formattedUsers = Number(unique_users).toLocaleString("en-US");

				setApiValues({
					circulating_supply: formattedSupply,
					mst_price: formattedPrice,
					avg_staking_yield: formattedRevenue,
					unique_users: formattedUsers,
				});

				setIsLoading(false); // Stop loading after data is fetched
			} catch (error) {
				console.error("Error fetching API data:", error);
				setIsLoading(false); // Ensure loading is stopped on error
			}
		};

		fetchApiValues();
	}, []);

	// Loading screen
	// Loading screen
	if (isLoading || forceLoading) {
		return (
			<div className="flex justify-center items-center h-screen bg-black">
				<img
					src="/img/icon.png"  // Referencing the logo from the public folder
					alt="Loading logo"
					className="w-24 h-24 animate-beat"
				/>
			</div>
		);
	}

	return (
		<>
			<section className="w-full flex items-center flex-col bg-home-hero-gradient relative min-h-[95vh] overflow-clip">
				<div className="text-center pt-[100px] sm:pt-[172px] pb-[100px] relative px-4 z-10">
					<div className="h-full fade-in-up">
						<h1 className="heading1_bold mb-4 sm:mb-8 text-white lg:text-[60px]">
							The All-in-one DeFi Hub for EVM-
							<br className="hidden sm:block" />
							Compatible Networks.
						</h1>
						<p className="subheading mx-auto max-w-[600px] text-gray-400 mt-[-10px] sm:mt-0 text-base sm:text-xl">
							Your Gateway to a Comprehensive and Seamless DeFi Journey.
						</p>
					</div>
				</div>
				<div className="absolute top-0 left-0 w-full h-full overflow-hidden">
					<div className="absolute top-0 left-[50%] -translate-x-1/2 w-full h-full min-w-[980px]">
						<video
							src={bannerVideo}
							autoPlay
							loop
							muted
							playsInline
							className="w-full h-full object-cover"
						></video>
					</div>
				</div>
			</section>
			<CountSection uniqueUsers={apiValues.unique_users} />
			<Partners />
		</>
	);
};

const CountSection = ({ uniqueUsers }) => {
	function formatNumberWithCommas(number) {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	const [tvl, setTvl] = useState(0.0);

	useEffect(() => {
		const getLiquidity = async () => {
			try {
				const response = await fetch("https://api.llama.fi/protocol/meridian");
				const data = await response.json();
				console.log(data, "TVL DATA");
				const baseTVL = data.currentChainTvls.Base;
				const fuseTVL = data.currentChainTvls.Fuse;
				const telosTVL = data.currentChainTvls.Telos;
				const meterTVL = data.currentChainTvls.Meter;
				const taikoTVL = data.currentChainTvls.Taiko;
				const borrowed = data.currentChainTvls.borrowed;
				let totalTVL =
					baseTVL + fuseTVL + telosTVL + meterTVL + taikoTVL + borrowed;
				totalTVL = Number(totalTVL).toFixed(2);
				const formattedTVL = formatNumberWithCommas(totalTVL);
				setTvl(formattedTVL);
			} catch (error) {
				console.error("Error fetching TVL data:", error);
			}
		};
		getLiquidity();
	}, []);

	return (
		<section className="w-full flex items-center justify-center relative bg-white">
			<div className="container">
				<div className="mb-12 mt-[-46px] sm:mt-[-52px] flex items-center justify-center flex-wrap gap-4 sm:gap-6">
					<div className="flex flex-col sm:flex-row sm:items-center sm:justify-between items-center gap-2 sm:gap-0 bg-blue-600 sm:bg-primary-blue shadow-sm p-4 sm:p-6 rounded-lg sm:w-[420px] grow basis-0 sm:grow-0 sm:basis-auto">
						<div className="inline-flex items-center text-white">
							<span className="heading5_semibold">${tvl}</span>
							<span className="sr-only heading5_semibold"></span>
						</div>
						<p className="sm:text-right paragraph text-white sm:text-gray-200">
							{" "}
							Total Value <br className="hidden sm:block" /> Locked{" "}
						</p>
					</div>
					<div className="flex flex-col sm:flex-row gap-2 sm:gap-0 sm:items-end sm:justify-between bg-white shadow-sm p-4 sm:p-6 rounded-lg sm:w-[420px] grow basis-0 sm:grow-0 sm:basis-auto">
						<h2 className="heading5_semibold text-black sm:text-gray-800 mb-0.5 sm:mb-0">
							{uniqueUsers}
						</h2>
						<p className="sm:text-right paragraph text-black sm:text-gray-800 sm:whitespace-pre-wrap">
							Unique Users
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

const Partners = () => {
	const data = [
		{ img: "/img/partner/1.svg" },
		{ img: "/img/partner/2.svg" },
		{ img: "/img/partner/3.svg" },
		{ img: "/img/partner/4.svg" },
		{ img: "/img/partner/5.svg" },
		{ img: "/img/partner/7.svg" },
		{ img: "/img/partner/1.svg" },
		{ img: "/img/partner/2.svg" },
		{ img: "/img/partner/3.svg" },
		{ img: "/img/partner/4.svg" },
		{ img: "/img/partner/5.svg" },
		{ img: "/img/partner/7.svg" },
	];

	return (
		<section className="pb-10">
			<div className="container mx-auto max-w-[1050px]">
				<Marquee gradient>
					{data.map((item, index) => (
						<div key={index} className="mx-4 sm:mx-7">
							<img src={item.img} alt="" />
						</div>
					))}
				</Marquee>
			</div>
		</section>
	);
};

export default Banner;
