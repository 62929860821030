import React from "react";
import { Discord, Github, Mail, X, caretdown } from "./Icons";

const Footer = () => {
	return (
		<footer
			className="w-full bg-linear-gradient-footer relative pt-20 pb-10 sm:pt-[90px] sm:pb-[70px]"
			style={{
				background:
					"url(/img/background_1.png) no-repeat center center / cover",
			}}
		>
			<div className="container">
				<div className="pb-10 lg:pt-[98px] sm:pb-[174px] md:text-center">
					<img
						src="/img/logo.svg"
						className="w-[274px] md:mx-auto mb-6"
						alt=""
					/>
					<h6 className="heading1_bold text-white lg:text-[65px]">
						The All-in-one DeFi Hub{" "}
						<br className="hidden sm:inline-block" /> for EVM Networks.
					</h6>
				</div>
				<div className="w-full flex md:flex-row flex-col gap-8 xl:gap-x-32 sm:pb-40">
					<div className="md:w-2/3 w-full flex flex-col gap-y-28 mb-10 sm:mb-0">
						<div className="w-full flex flex-wrap gap-y-[50px]">
							{data.map((item, index) => (
								<div
									key={index}
									className="sm:w-1/3 w-1/3 flex flex-col"
								>
									<h6 className="support_text_medium text-gray-200 uppercase">
										{item.name}
									</h6>
									<ul className="pt-5 flex flex-col gap-3">
										{item.submenu.map((subitem, index) =>
											subitem?.submenu ? (
												<li key={index}>
													<button
														type="button"
														className="flex items-center gap-1 paragraph3 text-gray-400/[44%] hover:text-gray-200 uppercase duration-200"
													>
														{subitem.name}
														{caretdown}
													</button>
													<div className="footer-dropdown-menu">
														<ul className="pt-3 flex flex-col gap-2">
															{subitem.submenu.map(
																(subsubitem, index) => (
																	<li key={index}>
																		<a
																			href={subsubitem.link}
																			target="-blank"
																			className="paragraph3 text-gray-400/[44%] hover:text-gray-200 uppercase duration-200"
																		>
																			{subsubitem.name}
																		</a>
																	</li>
																)
															)}
														</ul>
													</div>
												</li>
											) : (
												<li key={index}>
													<a
														href={subitem.link}
														target="-blank"
														className="paragraph3 text-gray-400/[44%] hover:text-gray-200 uppercase duration-200"
													>
														{subitem.name}
													</a>
												</li>
											)
										)}
									</ul>
								</div>
							))}
						</div>
					</div>
					<div className="md:w-1/3 w-full">
						<div className="w-full h-full flex flex-col justify-between gap-y-6 mb-6 sm:mb-0">
							<div className="flex flex-col items-start bg-primary-deepBlue rounded-lg p-6">
								<h2 className="support_text_medium text-gray-200 mb-4 uppercase">
									Meridian newsletter
								</h2>
								<p className="subheading text-gray-400 mb-10 pr-[25%] sm:pr-0">
									Be the first to know of our developments! Fresh
									updates in your inbox.
								</p>
								<form
									action=""
									className="w-full flex flex-col items-end"
								>
									<a
										href="https://medium.com/@meridianFinance"
										target="_blank"
										rel="noopener noreferrer"
										className="btn"
									>
										Subscribe
									</a>
								</form>
							</div>
						</div>
					</div>
				</div>
				<div className="flex items-center flex-wrap sm:flex-nowrap justify-between sm:mb-0">
					<p className="paragraph3 text-gray-400/[44%] w-full text-center sm:text-left mb-3 sm:mb-0">
						2024 Meridian. <br className="sm:hidden" /> All rights
						reserved.{" "}
					</p>
					<div className="w-full flex gap-2 justify-center sm:justify-end">
						{social.map((item, index) => (
							<a
								href={item.link}
								target="-blank"
								className="flex items-center group transition-all"
								key={index}
							>
								{item.icon}
							</a>
						))}
					</div>
				</div>
			</div>
		</footer>
	);
};
const data = [
	{
		name: "Applications",
		submenu: [
			{
				name: "Meridian Mint",
				link: "https://mint.meridianfinance.net",
			},
			{
				name: "Meridian Lend",
				link: "https://lend.meridianfinance.net/markets",
			},
			{
				name: "Meridian Trade",
				link: "https://trade.meridianfinance.net/#/v1",
			},
			{
				name: "Meridian Swap",
				link: "https://swaps.meridianfinance.net/",
			},
		],
	},
	{
		name: "Developers",
		submenu: [
			{
				name: "Documentation",
				link: "https://docs.meridianfinance.net",

			},
			{
				name: "Github",
				link: "https://github.com/MeridianDollar",
			},
		],
	},
	{
		name: "Governance",
		submenu: [
			{
				name: "Forum",
				link: "https://discord.com/invite/SCHCXz9Mnb",
			},
			{
				name: "Blog",
				link: "https://medium.com/@meridianFinance",
			},
			{
				name: "Snapshot",
				link: "https://snapshot.org/#/meridian-foundation.eth",
			},
			{
				name: "MERIDIAN MARKETING machine",
				link: "https://www.themeridianmarketingmachine.net",
			},
		],
	},
	{
		name: "Privacy",
		submenu: [
			{
				name: "Privacy Policy",
				link: "https://docs.meridianfinance.net/resources/legal/privacy-policy",
			},
			{
				name: "Terms",
				link: "https://docs.meridianfinance.net/resources/legal",
			},
		],
	},
	{
		name: "Security",
		submenu: [
			{
				name: "Audits",
				link: "https://docs.meridianfinance.net/resources/technical-and-security/usdm-technical-overview",
			},
			{
				name: "Oracles",
				link: "https://docs.meridianfinance.net/resources/technical-and-security/oracles",
			},
		],
	},
	{
		name: "Analytics",
		submenu: [
			{
				name: "DeFiLlama",
				link: "https://defillama.com/protocol/meridian#information",
			},
			{
				name: "DexScreener",
				link: "https://dexscreener.com/telos/0x205366d4243b21aeb8f4118e643466864b271c77",
			},
			{
				name: "DeBank",
				link: "https://debank.com/protocols/base_meridianfinance",
			},
			{
				name: "DappRadar",
				link: "https://dappradar.com/dapp/meridian-finance-2",
			},
		],
	},
];
const social = [
	{
		link: "https://github.com/MeridianDollar",
		icon: <Github />,
	},
	{
		link: "https://x.com/MeridianFi",
		icon: <X />,
	},
	{
		link: "https://discord.com/invite/SCHCXz9Mnb",
		icon: <Discord />,
	},
	{
		link: "https://docs.meridianfinance.net/resources/contact",
		icon: <Mail />,
	},
];
export default Footer;
