import React from "react";

const NextSlide = ({ color, ...rest }) => {
	return (
		<button type="button" {...rest}>
			<svg
				width="48"
				height="48"
				viewBox="0 0 48 48"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
					fill={color ? color : "#F2F5F8"}
				/>
				<path
					d="M19.292 33.2921C19.1991 33.385 19.1254 33.4953 19.0751 33.6167C19.0248 33.7381 18.999 33.8682 18.999 33.9996C18.999 34.131 19.0248 34.2611 19.0751 34.3825C19.1254 34.5039 19.1991 34.6142 19.292 34.7071C19.3849 34.8 19.4952 34.8737 19.6166 34.924C19.738 34.9742 19.8681 35.0001 19.9995 35.0001C20.1309 35.0001 20.261 34.9742 20.3824 34.924C20.5038 34.8737 20.6141 34.8 20.707 34.7071L30.707 24.7071C30.8 24.6142 30.8737 24.5039 30.9241 24.3825C30.9744 24.2611 31.0003 24.131 31.0003 23.9996C31.0003 23.8682 30.9744 23.738 30.9241 23.6166C30.8737 23.4952 30.8 23.3849 30.707 23.2921L20.707 13.2921C20.5194 13.1044 20.2649 12.999 19.9995 12.999C19.7341 12.999 19.4797 13.1044 19.292 13.2921C19.1044 13.4797 18.999 13.7342 18.999 13.9996C18.999 14.2649 19.1044 14.5194 19.292 14.7071L28.5858 23.9996L19.292 33.2921Z"
					fill="currentColor"
				/>
			</svg>
		</button>
	);
};

export default NextSlide;
